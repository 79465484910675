import img1 from "assets/icons/pool/eth.svg"
import img2 from "assets/icons/pool/tether.svg"
import img3 from "assets/icons/pool/usdc.svg"
import img5 from "assets/icons/pool/uni.svg"
import img6 from "assets/icons/pool/link.svg"
import img7 from "assets/icons/pool/wbtc.png"
import img8 from "assets/icons/pool/ape.png"

const tokensText = [
  {
    token: "ETH",
    tokenDesc: "Ethereum is a Proof-of-Stake blockchain that powers decentralized applications (dApps) through smart contracts. It has the largest ecosystem of decentralized applications and is home to numerous Layer 2 solutions like Arbitrum and Polygon.",
    site: "https://ethereum.org/"
  },
  {
    token: "USDT",
    tokenDesc: "Tether is a stablecoin pegged to the US Dollar. It is the first and most popular stablecoin, with the largest market capitalization and circulation.",
    ca: "0xdac17f958d2ee523a2206206994597c13d831ec7",
    site: "https://tether.to/"
  },
  {
    token: "USDC",
    tokenDesc: "USDC is a fully collateralized US dollar stablecoin that bridges dollars and trading on cryptocurrency exchanges, enabling value exchange across borders.",
    ca: "0xa0b86991c6218b36c1d19d4a2e9eb0ce3606eb48",
    site: "https://www.circle.com/en/usdc"
  },
  {
    token: "UNI",
    tokenDesc: "The UNI token allows holders to participate in the governance of the Uniswap protocol, including decisions on treasury usage and future upgrades.",
    ca: "0x1f9840a85d5af5bf1d1762f925bdaddc4201f984",
    site: "https://uniswap.org/"
  },
  {
    token: "LINK",
    tokenDesc: "Chainlink is a framework for building Decentralized Oracle Networks (DONs) that bring real-world data to blockchain networks, enabling hybrid smart contracts.",
    ca: "0x514910771af9ca656af840dff83e8264ecf986ca",
    site: "https://chain.link/"
  },
  {
    token: "WBTC",
    tokenDesc: "Wrapped Bitcoin is an ERC-20 token on Ethereum that represents Bitcoin. Each WBTC is backed 1:1 with Bitcoin, allowing BTC to be used in the Ethereum ecosystem.",
    ca: "0x2260fac5e5542a773aa44fbcfedf7c193bc2c599",
    site: "https://wbtc.network/"
  },
  {
    token: "APE",
    tokenDesc: "APE is a token made to support community-led initiatives in the metaverse, acting as a decentralized protocol layer for culture-driven projects.",
    ca: "0x4d224452801aced8b2f0aebe155379bb5d594381",
    site: "https://apecoin.com/"
  },
  {
    token: "BNB",
    tokenDesc: "BNB is a cryptocurrency coin created by Binance, initially as an ERC-20 token and later migrated to BNB Smart Chain. It is used to pay trading fees on Binance.",
    ca: "0xb8c77482e45f1f44de1745f52c74426c631bdd52",
    site: "https://www.binance.com/"
  },
];

export const poolDataSeparated = [
  {
    icon: img1,
    name: "Ethereum",
    token: "ETH",
    network: "Ethereum",
    id: "ethereum",
    address: "0x24b12457d3a68eca9daca48f0628276592bebcdd",
    addAddress: null,
    quantity: "0.3-5",
    index: 1,
    percent: "0.8",
    day: "14",
    text: tokensText[0],
  },
  {
    icon: img1,
    name: "Ethereum",
    token: "ETH",
    network: "Ethereum",
    id: "ethereum",
    address: "0xef110ca3c34a5074d455d687ac98f40c03d16173",
    addAddress: null,
    quantity: "3-10",
    percent: "2",
    day: "30",
    index: 2,
    text: tokensText[0],
  },
  {
    icon: img1,
    name: "Ethereum",
    token: "ETH",
    network: "Ethereum",
    id: "ethereum",
    address: "0x6bfad42cfc4efc96f529d786d643ff4a8b89fa52",
    addAddress: null,
    quantity: "5-25",
    percent: "5",
    day: "60",
    index: 3,
    text: tokensText[0],
  },
  {
    icon: img1,
    name: "Ethereum",
    token: "ETH",
    network: "Ethereum",
    id: "ethereum",
    address: "0xefb2e870b14d7e555a31b392541acf002dae6ae9",
    addAddress: null,
    quantity: "9-50",
    percent: "8.5",
    day: "90",
    text: tokensText[0],
    index: 4
  },
  {
    icon: img2,
    name: "Tether",
    token: "USDT",
    network: "Ethereum",
    id: "tether",
    address: "0x051f1d88f0af5763fb888ec4378b4d8b29ea3319",
    addAddress: "0xdac17f958d2ee523a2206206994597c13d831ec7",
    quantity: "5000-10000",
    percent: "0.7",
    day: "14",
    text: tokensText[1],
    index: 1
  },
  {
    icon: img2,
    name: "Tether",
    token: "USDT",
    network: "Ethereum",
    id: "tether",
    address: "0x290d7c4a8b0343605330fb1f7bab174655c4c1b7",
    addAddress: "0xdac17f958d2ee523a2206206994597c13d831ec7",
    quantity: "10000-30000",
    percent: "1.6",
    day: "30",
    text: tokensText[1],
    index: 2,
  },
  {
    icon: img2,
    name: "Tether",
    token: "USDT",
    network: "Ethereum",
    id: "tether",
    address: "0xc0947c152cd08f423b34a890126074d2648941ab",
    addAddress: "0xdac17f958d2ee523a2206206994597c13d831ec7",
    quantity: "20000-50000",
    percent: "4",
    day: "60",
    text: tokensText[1],
    index: 3
  },
  {
    icon: img2,
    name: "Tether",
    token: "USDT",
    network: "Ethereum",
    id: "tether",
    address: "0xc2e246f77be48c18c013ce5ab99346eec15dfd74",
    addAddress: "0xdac17f958d2ee523a2206206994597c13d831ec7",
    quantity: "30000-100000",
    percent: "6.5",
    day: "90",
    text: tokensText[1],
    index: 4
  },
  {
    icon: img3,
    name: "USD Coin",
    token: "USDC",
    network: "Ethereum",
    id: "usd-coin",
    address: "0x6e61031f7c35cc31a995e1c7fa32dbbeefe96a57",
    addAddress: "0xa0b86991c6218b36c1d19d4a2e9eb0ce3606eb48",
    quantity: "3000-10000",
    percent: "1.2",
    day: "14",
    text: tokensText[2],
    index: 1
  },
  {
    icon: img3,
    name: "USD Coin",
    token: "USDC",
    network: "Ethereum",
    id: "usd-coin",
    address: "0xe081d9d8fd994f0a17cd1edc4e6941a0333f686d",
    addAddress: "0xa0b86991c6218b36c1d19d4a2e9eb0ce3606eb48",
    quantity: "10000-30000",
    percent: "2.5",
    day: "30",
    text: tokensText[2],
    index: 2
  },
  {
    icon: img3,
    name: "USD Coin",
    token: "USDC",
    network: "Ethereum",
    id: "usd-coin",
    address: "0xbd55337583699e5f4849e3412ca2e05c39940a01",
    addAddress: "0xa0b86991c6218b36c1d19d4a2e9eb0ce3606eb48",
    quantity: "20000-50000",
    percent: "6",
    day: "60",
    text: tokensText[2],
    index: 3
  },
  {
    icon: img3,
    name: "USD Coin",
    token: "USDC",
    network: "Ethereum",
    id: "usd-coin",
    address: "0x94cc6c13da63573006b68f4652ef2c9214b708f3",
    addAddress: "0xa0b86991c6218b36c1d19d4a2e9eb0ce3606eb48",
    quantity: "30000-100000",
    percent: "10",
    day: "90",
    text: tokensText[2],
    index: 4
  },
  {
    icon: img5,
    name: "Uniswap",
    token: "UNI",
    network: "Ethereum",
    id: "uniswap",
    address: "0x1d42064fc4beb5f8aaf85f4617ae8b3b5b8bd801",
    addAddress: "0x1f9840a85d5af5bf1d1762f925bdaddc4201f984",
    quantity: "400-1000",
    percent: "2",
    day: "14",
    text: tokensText[3],
    index: 1
  },
  {
    icon: img5,
    name: "Uniswap",
    token: "UNI",
    network: "Ethereum",
    id: "uniswap",
    address: "0xd11aae487b1eaccdbb9fb021c0e84b4f030c0d9c",
    addAddress: "0x1f9840a85d5af5bf1d1762f925bdaddc4201f984",
    quantity: "1000-3500",
    percent: "5",
    day: "30",
    text: tokensText[3],
    index: 2
  },
  {
    icon: img5,
    name: "Uniswap",
    token: "UNI",
    network: "Ethereum",
    id: "uniswap",
    address: "0x678e94e3fd84e19da4fa59f0898a109acea86cfe",
    addAddress: "0x1f9840a85d5af5bf1d1762f925bdaddc4201f984",
    quantity: "2000-10000",
    percent: "11",
    day: "60",
    text: tokensText[3],
    index: 3
  },
  {
    icon: img5,
    name: "Uniswap",
    token: "UNI",
    network: "Ethereum",
    id: "uniswap",
    address: "0xb1102d897efeba4778fa7957619a5977efd0fb3d",
    addAddress: "0x1f9840a85d5af5bf1d1762f925bdaddc4201f984",
    quantity: "3000-20000",
    percent: "18",
    text: tokensText[3],
    day: "90",
    index: 4
  },
  {
    icon: img6,
    name: "Chainlink",
    token: "LINK",
    network: "Ethereum",
    id: "chainlink",
    address: "0x325a04e8ca8cf21d288a2fc833d237af42a6f702",
    addAddress: "0x514910771af9ca656af840dff83e8264ecf986ca",
    quantity: "500-1000",
    percent: "1.5",
    day: "14",
    text: tokensText[4],
    index: 1
  },
  {
    icon: img6,
    name: "Chainlink",
    token: "LINK",
    network: "Ethereum",
    id: "chainlink",
    address: "0x4efb71b05a09dc6973ca7a8fdc1f1e5c37de5fe2",
    addAddress: "0x514910771af9ca656af840dff83e8264ecf986ca",
    quantity: "1000-5000",
    percent: "3.5",
    day: "30",
    text: tokensText[4],
    index: 2
  },
  {
    icon: img6,
    name: "Chainlink",
    token: "LINK",
    network: "Ethereum",
    id: "chainlink",
    address: "0x84a4bb3809971a6739a0f0f07fdd29dc7b1d7c92",
    addAddress: "0x514910771af9ca656af840dff83e8264ecf986ca",
    quantity: "2000-8000",
    percent: "8",
    day: "60",
    text: tokensText[4],
    index: 3
  },
  {
    icon: img6,
    name: "Chainlink",
    token: "LINK",
    network: "Ethereum",
    id: "chainlink",
    address: "0xc2af45ff4505d0fb3d6e0009b8fda4bc223355f0",
    addAddress: "0x514910771af9ca656af840dff83e8264ecf986ca",
    quantity: "3000-10000",
    percent: "14.5",
    day: "90",
    text: tokensText[4],
    index: 4
  },
  {
    icon: img7,
    name: "Wrapped Bitcoin",
    token: "WBTC",
    network: "Ethereum",
    id: "wrapped-bitcoin",
    address: "0x4af9b31de2997db1f8428c3dcdbd404f8e27b455",
    addAddress: "0x2260fac5e5542a773aa44fbcfedf7c193bc2c599",
    quantity: "0.1-1",
    percent: "1",
    day: "14",
    text: tokensText[5],
    index: 1
  },
  {
    icon: img7,
    name: "Wrapped Bitcoin",
    token: "WBTC",
    network: "Ethereum",
    id: "wrapped-bitcoin",
    address: "0xd0101531b44e07cd091c1fd302dc42f0749b4063",
    addAddress: "0x2260fac5e5542a773aa44fbcfedf7c193bc2c599",
    quantity: "1-3",
    percent: "2.3",
    day: "30",
    text: tokensText[5],
    index: 2
  },
  {
    icon: img7,
    name: "Wrapped Bitcoin",
    token: "WBTC",
    network: "Ethereum",
    id: "wrapped-bitcoin",
    address: "0xe83a6aa2922fdd9b02ff785e60a07dfb2e0cf6f2",
    addAddress: "0x2260fac5e5542a773aa44fbcfedf7c193bc2c599",
    quantity: "2-5",
    percent: "5.3",
    day: "60",
    text: tokensText[5],
    index: 3
  },
  {
    icon: img7,
    name: "Wrapped Bitcoin",
    token: "WBTC",
    network: "Ethereum",
    id: "wrapped-bitcoin",
    address: "0x9e594f5b38c9f7ad4ad975a0a18106c8e18b4796",
    addAddress: "0x2260fac5e5542a773aa44fbcfedf7c193bc2c599",
    quantity: "4-15",
    percent: "9",
    day: "90",
    text: tokensText[5],
    index: 4
  },
  {
    icon: img8,
    name: "ApeCoin",
    token: "APE",
    network: "Ethereum",
    id: "apecoin",
    address: "0xbb0780090b4f69e5bbab9065104bffab357dfc55",
    addAddress: "0x4d224452801aced8b2f0aebe155379bb5d594381",
    quantity: "2000-5000",
    percent: "2",
    day: "14",
    text: tokensText[6],
    index: 1,
  },
  {
    icon: img8,
    name: "ApeCoin",
    token: "APE",
    network: "Ethereum",
    id: "apecoin",
    address: "0xc79707530a2aeb59a587780241ca74d128642fdd",
    addAddress: "0x4d224452801aced8b2f0aebe155379bb5d594381",
    quantity: "3000-8000",
    percent: "5.5",
    day: "30",
    text: tokensText[6],
    index: 2,
  },
  {
    icon: img8,
    name: "ApeCoin",
    token: "APE",
    network: "Ethereum",
    id: "apecoin",
    address: "0x2fee4d3d38e59774a4155a7d5f089308dbeb63ae",
    addAddress: "0x4d224452801aced8b2f0aebe155379bb5d594381",
    quantity: "5000-12000",
    percent: "12",
    day: "60",
    text: tokensText[6],
    index: 3,
  },
  {
    icon: img8,
    name: "ApeCoin",
    token: "APE",
    network: "Ethereum",
    id: "apecoin",
    address: "0xda7a4a45ce9c5b42102fcb456ae2532bed252a24",
    addAddress: "0x4d224452801aced8b2f0aebe155379bb5d594381",
    quantity: "8000-30000",
    percent: "18",
    day: "90",
    text: tokensText[6],
    index: 4,
  },
  {
    icon: img8,
    name: "ApeCoin",
    token: "APE",
    network: "Ethereum",
    id: "apecoin",
    address: "0x144fd7403a81149f4aa322ef71103a0e7b90fea8",
    addAddress: "0x4d224452801aced8b2f0aebe155379bb5d594381",
    quantity: "15000-500000",
    percent: "90",
    day: "365",
    text: tokensText[6],
    index: 4,
  },
];
